import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  LeftOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  WifiOutlined,
  PayCircleOutlined,
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { Notification } from '../../components';

import {
  Typography,
  Modal,
  message,
  Image,
  Input,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _cardbg from '../../assets/card_2.png';

import _whitelogo from '../../assets/logo-non-color.png';
import api from '../../api';
import useNotification from '../../hooks/useNotification';
import * as actions from '../../redux/actions/auth';
import connectCSKH from '../../utils/connectCSKH';
import CurrencyInput from 'react-currency-input-field';
import walletImage from '../../assets/wallet.jpg';
import './Wallet.scss';
import moment from 'moment';
import _ from 'lodash';
export default function Wallet() {
  const { cskh } = useSelector((state) => state._content);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const { profile } = useSelector((state) => state._auth);

  const { notifications, pushNotifications, pushNotificationsToCurrent } = useNotification();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentRequest, setCurrentRequest] = useState({});
  const [OTP, setOTP] = useState('');
  const [visibleOTP, setVisibleOTP] = useState(false);
  const [money, setMoney] = useState('');

  const [rut, setRut] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/users/profile');
      dispatch(actions.initialLogin(data.data));
      loadCurrentRequest()
    })();
    loadProfile();
  }, [notifications]);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    const { data } = await api.get('/users/profile');
    dispatch(actions.initialLogin(data.data));
    loadCurrentRequest();
    const contracts = await api.get('/contracts');
    // if (contracts.data[0].response !== 'Đã duyệt') {
    //   setRut(true);
    // }
    // if (contracts?.data?.data[0]?.isRut && contracts?.data?.data[0]?.isRut !== false) {
    //   setRut(true);
    // }
  };

  const loadCurrentRequest = async () => {
    try {
      const { data } = await api.get('/requests/lasted');
      setCurrentRequest(data?.data ? data.data : {});
      if(data?.data){
        const contracts = await api.get('/contracts');
        setContract(contracts.data.data[0]);
      }
    } catch (error) {
      setCurrentRequest({});
      console.log(error);
    }
  };

  const [contract, setContract] = useState({});
  const [visible, setVisible] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const onConfirmWithdraw = async (e) => {
    if (!currentRequest) {
      message.error('Lấy thông tin yêu cầu thất bại, vui lòng thử lại sau!');
      return;
    }

    if (profile.balance === 0) {
      message.error('Số dư khả dụng không đủ');
      return;
    }

    const contracts = await api.get('/contracts');

    if (contracts.data.data[0]) {
      const requestResult = await api.post('/requests', {
        amount: e,
        contractId: contracts.data.data[0]._id,
        bank_reciever: {
          name: profile?.kyc?.bank.name,
          number: profile?.kyc?.bank.number,
          bankName: profile?.kyc?.bank.bankName,
        },
      });
      setContract(requestResult.data);
      // setRut(requestResult.data.isRut);

      pushNotifications({
        message: `${profile.phone} yêu cầu rút tiền`,
        description: `Khách đang tạo yêu cầu rút tiền : ${profile.balance.toLocaleString()}`,
      });

      loadProfile();
      setVisible(true);
      setIsDone(true);

      console.log(1321321)


      // pushNotificationsToCurrent({
      //   message: `Thông báo khoản vay`,
      //   description: "" + e.toLocaleString(),
      //   description1: "Rút tiền",
      //   to: profile?._id
      // });


      return requestResult;
    } else {
      message.error(
        'Bạn chưa có hợp đồng vay, vui lòng đăng ký và hoàn thiện hồ sơ vay trước!'
      );
      return;
    }
  };

  const onClickVerify = async (e) => {
    try {
      // if (OTP === '') {
      //   message.error('Vui lòng nhập mã OTP');
      //   return;
      // }

      // if (OTP != profile?.kyc?.password1) {
      //   message.error('Mã OTP sai. Vui lòng thử lại');
      //   return;
      // }

      if (profile.balance === 0) {
        message.error('Số dư khả dụng không đủ.');
        return;
      }

      if (profile.balance < e) {
        message.error('Số dư không khả dụng.');
        return;
      }

      onConfirmWithdraw(e);
      setVisibleOTP(false);
      setOTP('');
      const contracts = await api.get('/contracts');
      setContract(contracts.data.data[0]);
      // const requestResult = await api.post('/requests/verify', {
      //   id: currentRequest._id,
      //   error: contracts?.data?.data[0].response || '',
      //   otp: OTP,
      //   status: contracts.data.data[0].status
      // });

      // loadProfile();
      // setOTP('');
      // setVisible(contracts.data.data[0].response !== 'accepted');
      // setIsDone(contracts.data.data[0].response === 'accepted');
      // setVisible(true);

      // setVisibleOTP(false);
      // onConfirmWithdraw(profile?.balance)
    } catch (error) {
      console.log(error);
    }
  };

  const [isConfirm, setIsConfirm] = useState(false);
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="header-container">
        <div />
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: '#fff' }}
        >
          Ví tiền
        </Typography.Text>
        <div onClick={() => history.push('/notifications')}>
          <Notification />
        </div>
      </div>
      <div style={{ padding: 10 }}>
        <Card
          data={profile}
          balance={profile?.balance}
          onWithdraw={(e) => onConfirmWithdraw(e)}
          currentRequest={currentRequest}
        />
        <motion.div whileTap={{ scale: 0.97, opacity: 0.3 }}>
            <Item
              text="Rút tiền về tài khoản liên kết"
              icon={<PayCircleOutlined className="pay-circle" />}
              onClick={
                profile?.kyc?.status
                  ? () => onClickVerify(profile?.balance)
                  : () => message.info('Bạn cần xác minh danh tính.')
              }
            />
          {/* {!rut ? (
            <div className="item" style={{ background: '#6d6d6d' }}>
              <Typography.Text
                style={{
                  flex: 1,
                  fontSize: 16,
                  paddingLeft: 20,
                  color: '#fff',
                }}
                strong
              >
                Rút tiền về tài khoản liên kết
              </Typography.Text>
              <VerticalAlignBottomOutlined
                style={{ color: '#fff', fontSize: 30 }}
              />
            </div>
          ) : (
            <Item
              text="Rút tiền về tài khoản liên kết"
              icon={<PayCircleOutlined className="pay-circle" />}
              // onClick={() => setVisibleOTP(true)}
              onClick={
                profile?.kyc?.status
                  ? onClickVerify
                  : () => message.info('Bạn cần xác minh danh tính.')
              }
            />
          )} */}
        </motion.div>
        {!_.isEmpty(currentRequest) && (
          <div className="request-detail">
            <div className="title-detail">Chi Tiết Giải Ngân</div>
            <table>
              <tr>
                <td>Thời gian rút tiền</td>
                <td>
                  {moment(currentRequest.created_at).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )}
                </td>
              </tr>
              <tr>
                <td>Thực rút về tài khoản</td>
                <td>
                  {currentRequest.amount
                    ? currentRequest.amount.toLocaleString() + ' đ'
                    : ''}
                </td>
              </tr>
              <tr>
                <td>Trạng thái rút tiền</td>
                <td>{contract.status1 ?? 'Đang chờ xử lý'}</td>
              </tr>
              <tr>
                <td>Ghi chú</td>
                <td>{contract.response1}</td>
              </tr>
            </table>
          </div>
        )}

        <Image
          src={walletImage}
          style={{ padding: 5, borderRadius: 10 }}
          preview={false}
        />
      </div>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={true}
        onCancel={() => {
          setVisible(false);
          setIsConfirm(false);
        }}
        destroyOnClose
      >
        <div className="verifying">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
                 <>
                    <>
                      <ExclamationCircleOutlined style={{ fontSize: 50, color: 'red' }} />
                      <Typography.Text
                        style={{
                          color: '#777',
                          fontSize: 17,
                          textAlign: 'center',
                          padding: 10,
                          paddingBottom: 4,
                          fontWeight: 'bold',
                        }}
                      >
                        RÚT TIỀN THẤT BẠI
                      </Typography.Text>
                    </>
                  </>

                  {
                    contract?.response1 && <Typography.Text
                    style={{
                      color: '#777',
                      fontSize: 17,
                      textAlign: 'center',
                      padding: 4,
                      textTransform: 'unset',
                    }}
                  >
                    {contract?.response1}
                    </Typography.Text>
                  }

                  

                <Typography.Text style={{ color: '#777', fontSize: 17 }}>
                  Liên hệ CSKH trực tuyến để được hỗ trợ
                </Typography.Text>
                <br />

                <motion.div
                  whileTap={{ opacity: 0.4, scale: 0.97 }}
                  style={{
                    background: '#002dbf',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10,
                    padding: 5,
                  }}
                  onClick={() => connectCSKH(profile)}
                >
                  <Typography.Text
                    style={{ fontSize: 17, color: '#fff', fontWeight: 'bold' }}
                  >
                    Ấn vào đây để liên hệ CSKH
                  </Typography.Text>
                </motion.div>
          </div>
        </div>
      </Modal>



      <Modal
        visible={visibleOTP}
        title={'Nhập số tiền rút'}
        footer={null}
        closable={true}
        onCancel={() => setVisibleOTP(false)}
        destroyOnClose
      >
        <div className="withdraw-money-container">
          <div style={{ marginBottom: 13 }}>
            <Typography.Text>Số dư hiện tại: <strong style={{ color: "red" }}>{profile?.balance?.toLocaleString()}</strong> VND</Typography.Text>
          </div>
          <Input
            className="input-currency"
            placeholder="Nhập số tiền rút"
            value={money}
            onChange={(e) => setMoney(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={onClickVerify}
            >
              <Typography.Text
                style={{ color: '#fff', fontWeight: 700, fontSize: 16 }}
              >
                Xác nhận
              </Typography.Text>
            </motion.div>
          </div>

          {/* <div style={{ marginTop: 20, color: 'red' }}>
            Vui lòng liên hệ CSKH để nhận mật khẩu rút tiền
          </div> */}
        </div>
      </Modal>
    </motion.div>
  );
}

function Card({ data, balance, onWithdraw, currentRequest, setVisibleOTP }) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState(0);
  const [showMoney, setShowMoney] = useState(true);
  const [showBankAccount, setShowBankAccount] = useState(false);
  return (
    <motion.div
      style={{ padding: 5 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            borderRadius: 5,
            width: '100%',
            height: 200,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundImage: `url(${_cardbg})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="card-head-img">
            <div>
              {data?.kyc?.bank?.bankName ? (
                <h2 style={{ fontSize: 20, fontWeight: 600, color: '#fff' }}>
                  {data?.kyc?.bank?.bankName}
                </h2>
              ) : (
                <WifiOutlined
                  style={{
                    fontSize: 26,
                    color: '#fff',
                    fontWeight: 'bold',
                    transform: 'rotate(90deg)',
                  }}
                />
              )}

              {/*<WifiOutlined*/}
              {/*  style={{*/}
              {/*    fontSize: 26,*/}
              {/*    color: '#fff',*/}
              {/*    fontWeight: 'bold',*/}
              {/*    transform: 'rotate(90deg)',*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          </div>
          <div
            style={{
              padding: 10,
              justifyContent: 'flex-start',
              minWidth: '100%',
            }}
          >
            <div
              className="atm-card-information"
              style={{ position: 'relative' }}
            >
              {data?.kyc?.bank?.number ? (
                <>
                  <div style={{ position: 'absolute', top: 0, right: '10px' }}>
                    {showBankAccount ? (
                      <EyeOutlined
                        onClick={() => setShowBankAccount((prev) => !prev)}
                        className="eye-icon"
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        onClick={() => setShowBankAccount((prev) => !prev)}
                        className="eye-icon"
                      />
                    )}
                  </div>

                  <Typography.Text className="atm-card-text">
                    {data?.kyc?.bank?.number
                      ? showBankAccount
                        ? `${data.kyc.bank.number}`
                        : `********` +
                          data.kyc.bank.number.substring(
                            data.kyc.bank.number.length - 4
                          )
                      : ''}
                  </Typography.Text>
                  <Typography.Text className="atm-card-text">
                    {data?.kyc?.bank?.name}
                  </Typography.Text>
                </>
              ) : (
                <Typography.Text className="atm-card-text">
                  Chưa đăng ký
                </Typography.Text>
              )}
            </div>
          </div>
        </div>
      </div>
      <motion.div
        className="check-amount-container"
        onClick={() => {
          setVisible(true);
        }}
      >
        <motion.div style={{}} whileTap={{ opacity: 0.3 }}>
          <Typography.Text
            style={{ fontSize: 15, color: '#333', fontWeight: '600' }}
          >
            Số dư ví :
          </Typography.Text>
          <br />
          <div />
        </motion.div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {/*{showMoney ? (*/}
          {/*  <EyeOutlined*/}
          {/*    onClick={() => setShowMoney((prev) => !prev)}*/}
          {/*    className="eye-icon"*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <EyeInvisibleOutlined*/}
          {/*    onClick={() => setShowMoney((prev) => !prev)}*/}
          {/*    className="eye-icon"*/}
          {/*  />*/}
          {/*)}*/}
          {/*<br />*/}
          <Typography.Text
            style={{ fontSize: 17, color: '#CE4F53', fontWeight: 700 }}
          >
            {/*{showMoney ? `${data?.balance?.toLocaleString()}  VND` : '******'}{' '}*/}
            {data?.balance?.toLocaleString()} VND
          </Typography.Text>
        </div>
      </motion.div>
      <a
        onClick={() => history.push('/history')}
        style={{ textDecoration: 'underline', margin: 5 }}
      >
        Biến động số dư
      </a>
      <Modal
        visible={visible}
        title={'Rút tiền'}
        footer={null}
        closable={true}
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <div className="withdraw-money-container">

        <div style={{ marginBottom: 13 }}>
            <Typography.Text>Số dư hiện tại: <strong style={{ color: "red" }}>{balance?.toLocaleString()}</strong> VND</Typography.Text>
          </div>
          
          <CurrencyInput
            className="input-currency"
            min={0}
            max={balance}
            placeholder="Nhập số tiền rút"
            decimalsLimit={2}
            onValueChange={(value, name) => setAmount(parseInt(value))}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              className="check-amount"
              onClick={() => {
                if (amount > data?.balance) {
                  message.info('Không thể cao hơn mức khả dụng');
                  return;
                }
                setVisible(false);
                onWithdraw(amount);
              }}
            >
              <Typography.Text
                style={{ color: '#fff', fontWeight: 700, fontSize: 16 }}
              >
                Xác nhận
              </Typography.Text>
            </motion.div>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}
const Item = ({ text = 'title', icon, onClick = () => {} }) => {
  return (
    <div className="item" onClick={onClick}>
      <Typography.Text
        style={{ flex: 1, fontSize: 16, paddingLeft: 20, color: '#fff' }}
        strong
      >
        {text}
      </Typography.Text>
      <VerticalAlignBottomOutlined style={{ color: '#fff', fontSize: 30 }} />
    </div>
  );
};

const renderStatus = (status) => {
  switch (status) {
    case 'Đang xem xét':
      return 'Đang xét duyệt';
    case 'Đã duyệt':
      return 'Đang xử lý';
    case 'Từ chối':
      return 'Từ chối';
  }
  return '';
};
