import React, { useState, useEffect } from 'react';
import { Badge, Typography, Carousel, Image, Button } from 'antd';
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';
import tickAsset from '../../assets/tick.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notification } from '../../components';
import home from '../../assets/ban.jpg';
import anh1 from '../../assets/bg123.jpeg';
import anh2 from '../../assets/bg11.jpg';
import anh3 from '../../assets/anh3.jpeg';

import banner1 from '../../assets/banner/1.png';
import banner2 from '../../assets/banner/2.png';
import banner3 from '../../assets/banner/3.png';
import banner4 from '../../assets/banner/4.png';
import banner5 from '../../assets/banner/5.jpg';


import bannerbottom1 from '../../assets/bannerbottom/1.png';
import bannerbottom2 from '../../assets/bannerbottom/2.webp';
import bannerbottom3 from '../../assets/bannerbottom/3.jpg';
import bannerbottom4 from '../../assets/bannerbottom/4.jpeg';
import sp from '../../assets/sp.jpg';

import icon1 from '../../assets/icon1.png';


import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
const arrayText = [
  '097***4 đã rút 35.000.000 VND',
  '033***8 đã rút 30.000.000 VND',
  '087***3 đã rút 45.000.000 VND',
  '033***1 đã rút 50.000.000 VND',
  '035***5 đã được duyệt hồ sơ',
  '033***5 đã rút 45.000.000 VND',
  '035***2 đã được duyệt hồ sơ',
  '033***1 đã rút 30.000.000 VND',
  '087***9 đã rút 45.000.000 VND',
  '035***8 đã được duyệt hồ sơ',
];

const banenr = [banner1, banner2, banner3, banner4, banner5];
const banenrbottom = [
  bannerbottom1,
  bannerbottom2,
  bannerbottom3,
  bannerbottom4,];

export default function Home() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { cskh } = useSelector((state) => state._content);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < arrayText.length - 1)
        setCurrentIndex((prev) => prev + 1);
      else setCurrentIndex((prev) => 0);
    }, 1800);
    return () => clearInterval(interval);
  });
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="container"
    >
      <div className="header-content" style={{ gap: '10px' }}>
        <div className="user-info">
          <Typography.Text className="text-head">Xin chào, </Typography.Text>
          <br />
          <Typography.Text className="text-head">
            {profile?.kyc?.name || profile.phone}
          </Typography.Text>
        </div>

        <div className="contact-info " style={{ textAlign: 'right', width: '100%' }}>
         <div>Tổng đài CSKH</div>
         <div>Hotline : 1900998887</div>
        </div>

        <motion.div
          whileTap={{ scale: 0.9, opacity: 0.7 }}
          onClick={() => history.push('/notifications')}
        >
          <Notification />
        </motion.div>
      </div>

      <div className="noti-text-container">
        <Typography.Text className="noti-text" style={{ color: '#e11931' }}>
          {arrayText[currentIndex]}
        </Typography.Text>
      </div>
      <div>
        <Carousel autoplay autoplaySpeed={4000} className="carousel-container">
          {
              banenr.map((item, index) => (
               <div key={index} > 
                  <div className="carousel" style={{ backgroundImage: `url(${item})` }}>
                  <div className="sl-text-container"></div>
                </div>
             </div>
              ))
            }
        </Carousel>
      </div>
      <Button
        className="choose-btn"
        onClick={() => {
          history.push('/vay');
        }}
      >
        <Typography.Text className="animtion1"
          style={{ color: '#fff', fontWeight: 'normal', fontSize: 20 }}
        >
          👉 Đăng ký vay ngay 👈
        </Typography.Text>
      </Button>


      <div style={{ textAlign: 'center', marginTop: 20, padding: 20 }}>
        <Typography.Title level={3} style={{ textTransform: 'uppercase', fontSize: 18, color: "rgb(225, 25, 49)" }}>Mô tả sản phẩm</Typography.Title>

        <div>
          <Typography.Text style={{color: "rgb(225, 25, 49)"}}>
          ✓ Vay tiền online. Kỳ hạn linh hoạt 3, 6, 9, 12, 24, 36, 48, 60 tháng
          </Typography.Text>
        </div>

        <div>
          <img style={{ maxWidth: '100%', borderRadius: 10, marginTop: 20 }} src={sp}/>
        </div>
      </div>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
          paddingTop: 10,
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
        <Typography.Title style={{ color: "rgb(225, 25, 49)", fontSize: 18}}>Lợi ích khách hàng</Typography.Title>
        </div>
        <Tab
          title={
            <span style={{ color: '#e11931' }}>
              ➤ Lãi suất thấp 0.8%/tháng
            </span>
          }
          icon={<AlertFilled style={{ fontSize: 20, color: '#ff7323' }} />}
        />
        <Tab
          title={
            <span style={{ color: '#e11931' }}>
              ➤ Giải ngân nhanh chóng
            </span>
          }
          icon={<AlertFilled style={{ fontSize: 20, color: '#ff7323' }} />}
        />
        <Tab
          title={
            <span style={{ color: '#e11931' }}>
              ➤ Không giấy tờ gốc, không thế chấp
            </span>
          }
          icon={<DashboardFilled style={{ fontSize: 20, color: '#008f50' }} />}
        />
        <Tab
          title={
            <span style={{ color: '#e11931' }}>
              ➤ Thủ tục online dễ dàng 5 phút
            </span>
          }
          icon={<SlidersFilled style={{ fontSize: 20, color: '#e11931' }} />}
        />

        <Tab
          title={
            <span style={{ color: '#e11931' }}>
              ➤ Không phí ẩn, không phí phát sinh
            </span>
          }
          icon={<DashboardFilled style={{ fontSize: 20, color: '#008f50' }} />}
        />


      </div>
      <Carousel autoplay autoplaySpeed={4000} className="carousel-container">
        {
          banenrbottom.map( (item, index) => (
               <div key={index}>
              <div className="carousel" style={{ backgroundImage: `url(${item})`, height: 250}}> 
                <div className="sl-text-container"></div>
              </div>
            </div>
          ))
        }
      </Carousel>

      

      <div style={{ textAlign: 'center', marginTop: 20, padding: 20 }}>
        <a target="_blank" href={cskh.length ? cskh[0] : '/'}>
        <Typography.Title level={3} onClick={() => history.push('/cskh')} 
        style={{ margin: "auto", paddingLeft: 20, paddingRight: 20, borderRadius: 25, textTransform: 'uppercase', fontSize: 18, color: "#fff", backgroundColor: "#e11931", paddingTop: 10, paddingBottom: 10, textAlign: "center", width: "fit-content", fontWeight: "normal" }}>
          <span className="animtion1">Liên hệ tư vấn - hỗ trợ</span>
          </Typography.Title>
        </a>
      </div>
      <div
        style={{
          paddingTop: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Image src={icon1} preview={false} style={{ maxWidth: 100 }} />
        <Image src={tickAsset} preview={false} style={{ maxWidth: 100 }} />
        </div>
        <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#555',
            textAlign: 'center',
            padding: '10px 30px 50px',
          }}
        >
          <br /> ® Bản quyền thuộc về CÔNG TY TÀI CHÍNH TNHH MTV HOME CREDIT VIỆT NAM.
          <br /> Tất cả các quyền được bảo hộ.
          <br /> Copyright © 2023 HOME CREDIT
        </Typography.Text>
      </div>
    </motion.div>
  );
}
const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: '1px solid #006997',
      }}
      onClick={onClick}
      style={{
        margin: '15px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 15px',
        border: '1px dashed rgb(225, 25, 49)',
        borderRadius: 25,
      }}
    >
      <Typography.Text
        style={{ flex: 1, fontSize: 16, color: '#006997', fontWeight: 500 }}
      >
        {title}
      </Typography.Text>
      {icon}
    </motion.div>
  );
};
