import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../api/';
import moment from 'moment';

import _img from '../../assets/copy.png';
import { Typography, Empty, Spin, Image } from 'antd';
import { motion } from 'framer-motion';
import {
  LeftOutlined,
  LoadingOutlined,
  CheckOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';
import './history.scss';
import { useSelector } from 'react-redux';
// import _img from '../../assets/success.jpg';

async function copyToClipboard(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;

    // Move the textarea outside the viewport to make it invisible
    textarea.style.position = 'absolute';
    textarea.style.left = '-99999999px';

    document.body.prepend(textarea);

    // highlight the content of the textarea element
    textarea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.log(err);
    } finally {
      textarea.remove();
    }
  }
}
export default function () {
  const history = useHistory();
  const { bankDetails } = useSelector((state) => state._content);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState({});
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get('/contracts');
      if (data.data.length > 0) setContract(data.data[0]);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get('/payments');
      setRequests([...data.data]);
      setLoading(false);
    })();
  }, []);
  return (
    <div style={{ backgroundColor: '#fafafa' }}>
      <div className="head-container">
        <div
          className="head"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <motion.div whileTap={{ scale: 0.95, x: -10 }}>
            <LeftOutlined
              className="left-icon"
              onClick={() => history.replace('/wallet')}
            />
          </motion.div>
          <Typography.Text strong style={{ color: '#ffffff', fontSize: 17 }}>
            Lịch sử giao dịch
          </Typography.Text>
          <div />
        </div>
      </div>

      <div style={{ padding: 10, paddingTop: 30, paddingBottom: 20 }}>
        {loading ? (
          <div className="loading">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
            <Typography.Text>Đang tải dữ liệu...</Typography.Text>
          </div>
        ) : (
          <>
            {requests.length == 0 && <Empty description="Chưa có dữ liệu" />}
            {requests.map((item, index) => (
              <Item item={item} key={index} />
            ))}
          </>
        )}
      </div>
      {/*</motion.div>*/}
    </div>
  );
}

const Item = ({ item }) => {
  return (
    <div className="item-box" style={{ margin: 5 }}>
      <div>
        <Typography.Text className="item-text">
          {moment(item.created_at).format('hh:mm A DD/MM/YYYY')}
        </Typography.Text>
        <br />
        <Typography.Text className="item-text">
          {item.description}
        </Typography.Text>
        <br />
        <Typography.Text className="item-text">
          {item.status ? '+ ' : '- '}
          {item.amount.toLocaleString()} đ
        </Typography.Text>
      </div>
      <div>
        {item.status ? (
          <CheckOutlined className="check-icon" />
        ) : (
          <ExclamationOutlined className="exclamation-icon" />
        )}
      </div>
    </div>
  );
};
