import React from 'react';
import './cskh.scss';
import cskh from '../../assets/cskh.jpeg';
import cskh1 from '../../assets/aTiS4L.png';
import { Image, Typography, Button } from 'antd';
import zalo from '../../assets/zalo.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { tel } from '../../assets/telegram.png';
import zlcskh from '../../utils/connectCSKH';
import tickAsset from '../../assets/tick.png';
import { Notification } from '../../components'; 

export default function Cskh() {
  const history = useHistory();
  return (
    <div className="container cskh">
      <div className="header-content-cskh">
        <div></div>
        <div>
          <Typography.Text className="text-head">
            Thông tin chăm sóc khách hàng
          </Typography.Text>
        </div>
        <div onClick={() => history.push('/notifications')}>
          <Notification />
        </div>
      </div>
      <div className="headercskh">
        {/* <Image src={cskh} preview={false} className="head-img" /> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* <div>
            <div style={{ color: '#0066cc' }}>Trung tâm</div>
            <div style={{ color: '#0066cc', fontWeight: 'bold' }}>
              CHĂM SÓC KHÁCH HÀNG
            </div>
            <div style={{ color: '#0066cc', fontWeight: 'bold', fontSize: 24 }}>
              1900996694
            </div>
          </div> */}
          <Image style={{ width: "100%" }} src={cskh} preview={false} className="head-img" />
        </div>
      </div>
      <div className="body">
        <Image src={tickAsset} preview={false} style={{ maxWidth: 100 }} />

        {/* <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#555',
            textAlign: 'center',
          }}
        >
          <br /> Tầng 3, Tòa nhà VIT, 519 Kim Mã, Quận Ba Đình, Hà Nội, Việt Nam
          <br /> &reg; Bản quyền thuộc về
          <br /> HOMECREDIT
        </Typography.Text> */}
      </div>
    </div>
  );
}
